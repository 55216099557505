import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactPlayer from 'react-player';
import Button from '../../button/Button';
import { useChain, useWallet } from '../../../hooks/useEventStates';
import dispatchEvent from '../../../fns/common/dispatchEvent';
import injected from '../../../fns/wallets/injected';
import walletConnect from '../../../fns/wallets/walletConnect';
import Slider from 'react-slick';

import slide1 from '../../../assets/images/poker/mon_day.png';
import slide2 from '../../../assets/images/poker/Wednes_day.png';
import slide3 from '../../../assets/images/poker/thurs_day.png';
import slide4 from '../../../assets/images/poker/sat_day.png';
import slide5 from '../../../assets/images/poker/SatCollege1.png'
import slide6 from '../../../assets/images/poker/sun_day.jpg';
import slide7 from '../../../assets/images/poker/sun_day_poker.png';



const Vip = () => {
  const account = useWallet();
  const chainId = useChain();

  const sliderSettings = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
  };

  return (
    <section className="pb-16 home-vip">
      <div className="px-5 py-10 md:px-10 lg:py-auto">
        <div className="flex flex-col items-center xl:flex-row">
          <div className="w-full mr-0 xl:w-1/3 xl:mr-5">
            <h1 className="mb-3 text-4xl font-bold tracking-tight lt-white md:text-5xl">
              Enjoy the Perks of Being a MetaFans NFT Holder
            </h1>
            <h2 className="mb-5 text-xl font-bold tracking-tight text-grey md:text-2xl">
              Connect with our Ultimate Sports & Poker Club. The new MetaFans season start August 28th, 2024!<br/>
              <a
                className="mb-2 underline "
                href="https://discord.gg/metafans"
                target="_blank"
              >
                Join our Discord!
              </a>
            </h2>
            <div className="flex items-center justify-start vip-ctas">
              {!(account && chainId) ? (
                <Button
                  variant="Primary"
                  className="px-3 rounded-lg w-fit border-primary sm:px-5"
                  onClick={() => {
                    const connector = window.ethereum
                      ? injected
                      : walletConnect;
                    connector
                      .connect()
                      .catch((err) => toast.error(err.message));
                  }}
                >
                  Connect Wallet
                </Button>
              ) : (
                <Button
                  variant="Primary"
                  className="px-3 rounded-lg w-fit border-primary sm:px-5"
                  onClick={() => dispatchEvent('opensaleModalRequested')}
                >
                  Mint
                </Button>
              )}

              <Link
                className="px-3 ml-2 rounded-lg learn w-fit border-primary sm:px-5"
                to="/nft-utilities"
              >
                <p className="text-xs font-bold text-white sm:text-sm">
                  Learn More
                </p>
              </Link>
            </div>
          </div>
          <div className="w-full mt-8 xl:w-2/3 xl:mt-0 lg:pl-40 vip-slider">
            <Slider {...sliderSettings}>
              <img className="h-[50vh] lg:h-[65vh] rounded-3xl object-contain" src={slide1} alt="metafans photos"></img>
              <img className="h-[50vh] lg:h-[65vh] rounded-3xl object-contain" src={slide2} alt="irl metafans photos"></img>
              <img className="h-[50vh] lg:h-[65vh] rounded-3xl object-contain" src={slide3} alt="irl metafans photos"></img>
              <img className="h-[50vh] lg:h-[65vh] rounded-3xl object-contain" src={slide4} alt="irl metafans photos"></img>
              {/* <img className="h-[50vh] lg:h-[65vh] rounded-3xl object-contain" src={slide5} alt="irl metafans photos"></img> */}
              <img className="h-[50vh] lg:h-[65vh] rounded-3xl object-contain" src={slide6} alt="irl metafans photos"></img>
              <img className="h-[50vh] lg:h-[65vh] rounded-3xl object-contain" src={slide7} alt="irl metafans photos"></img>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Vip;
